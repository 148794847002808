import './App.css';
import React from 'react';

class Row extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var query = `/api/1/factor?input=${this.props.input}&quote_numbers=true`;
    if (this.props.base !== undefined) {
      query += `&base=${this.props.base}`;
    }
    console.log(query);

    fetch(query)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error === undefined) {
            this.setState({palindromes: result.palindromes});
          } else {
            this.setState({error: result.error});
          }
        },
        (error) => {
          this.setState({error: error.toString()});
        }
      );
  }

  render() {
    let answers;
    if (this.state.palindromes !== undefined) {
      answers = <>
        <td>{this.state.palindromes[0]}</td>
        <td>{this.state.palindromes[1]}</td>
        <td>{this.state.palindromes[2]}</td>
      </>;
    } else if (this.state.error !== undefined) {
      answers = <td colSpan='3'>{this.state.error.toString()}</td>;
    } else {
      answers = <td colSpan='3'>Loading</td>;
    }

    return (
      <tr>
        <td>{this.props.input}</td>
        <td>{this.props.base}</td>
        {answers}
      </tr>
    );
  }
}

class InputRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {input: '', base: '10'};

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handleBaseChange = this.handleBaseChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    this.setState({input: event.target.value});
  }

  handleInputKeyDown(event) {
    if (event.key === 'Enter') {
      this.handleSubmit(event);
    }
  }

  handleBaseChange(event) {
    this.setState({base: event.target.value})
  }

  handleSubmit(event) {
    this.props.onSubmit(this.state.input, this.state.base);
    this.setState({input: ''});
    event.preventDefault();
  }

  render() {
    return (
      <tr>
        <td>
          <input type="text" placeholder="Press Return to submit" value={this.state.input} onChange={this.handleInputChange} onKeyDown={this.handleInputKeyDown} />
        </td>
        <td>
          <select value={this.state.base} onChange={this.handleBaseChange}>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
      </tr>
    );
  }
}

export default class App extends React.Component {
  constructor(props) {
    super(props);

    let params = new URLSearchParams(window.location.search);
    let initial = params.get('initial');
    let rows;
    if (initial !== null) {
      rows = [{number: initial, timestamp: Date.now()}];
    } else {
      rows = [];
    }

    this.state = {rows: rows};
    this.submitNumber = this.submitNumber.bind(this);
  }

  submitNumber(n, base) {
    var rows = this.state.rows;
    rows.unshift({number: n, base: base, timestamp: Date.now()});
    this.setState({rows: rows});
  }

  render() {
    const rows = this.state.rows.map((row, i) => {
      return <Row key={row.timestamp} input={row.number} base={row.base} />;
    });

    return (
      <div className="App">
        <table>
          <thead>
            <tr><td>Input</td><td>Base</td><td colSpan="3">Addend Palindromes</td></tr>
          </thead>
          <tbody>
            <InputRow onSubmit={this.submitNumber} />
            {rows}
          </tbody>
        </table>
      </div>
    );
  }
}

